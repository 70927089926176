import React from 'react'
import './Program.css'
import {programsData} from '../../data/programsData'
import rightArrow from '../../assets/rightArrow.png'
const Program = () => {
  return (
    <div className='Program' id='program'>
      <div className="program-header">
        <span className='stroke-text'>EXPLORE</span>
        <span>YOUR PROGRAM</span>
        <span className='stroke-text'>TO SHAPE YOU</span>
      </div>
      <div className="program-category">
        {programsData.map((program)=>(
          <div className="category">
            {program.image}
            <span>{program.heading}</span>
            <span>{program.details}</span>
            <div className="join-now">
              <span>Join Now</span>
              <img src={rightArrow} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Program
