import './App.css';
import Hero from './components/Hero/Hero';
import Plan from './components/plan/Plan';
import Program from './components/program/Program';
import Reason from './components/reason/Reason';
import Footer from './components/Footer/Footer';
import Join from './components/Join/Join';
import Testimonial from './components/Testimonial/Testimonial';
import Alert from './components/Alert/Alert';
import { useState } from 'react';
function App() {
  const [alert,setAlert]=useState(null);
  const showAlert=(Message,type)=>{
    setAlert({
      msg: Message,
      type:type
    })
    setTimeout(()=>{
      setAlert(null);
    },1500);
  }
  return (
    <div className="App" >
      <Alert alert={alert}/>
      <Hero/>
      <Program/>
      <Reason/>
      <Plan/>
      <Testimonial/>
      <Join showAlert={showAlert}/>
      <Footer/>
      </div>
    
  );
}

export default App;
