import React from "react";
import "./Join.css";
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
export default function Join(props) {
  const Popup=()=>{
    props.showAlert("Successfully!!! submited",'success')
  }
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_aplzm7q', 'template_r7677qr', form.current, '84LvF-W5EH5GOKt5w')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div className="Joinus" id="joinus">
      <div className="left-j">
        <hr />
        <div>
          <span className="stroke-text">Ready to </span>
          <span>level up</span>
        </div>
        <div>
          <span>your body </span>
          <span className="stroke-text">with us !!</span>
        </div>
      </div>
      <div className="right-j">
        <form action="email" ref={form} onSubmit={sendEmail} className="container">
          <div><input type="email" name="user-email" placeholder=" Email-Id" id="" /></div>
          <div><input type="text" name="user-name" placeholder=" Name" /></div>
          <div><input type="number" name="phone" placeholder="Phone No." id="" /></div>
          <div><button onClick={Popup} className="btn btn-j">Join Now</button></div>
        </form>
      </div>
    </div>
  );
};


