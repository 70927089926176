
import './Header.css'
import Logo from  '../../assets/logo.png'
const Header = () => {
  return (
    <div className='header'>
      <img src={Logo} alt="" className='logo'/>
      <ul className='list-menu'>
        <li 
        onClick={()=>{
          window.scrollBy({top:40,behavior:'smooth'});
        }}>Home</li>
        <li
        onClick={()=>{
          window.scrollBy({top:700,behavior:'smooth'});
        }}>Program</li>
        <li
        onClick={()=>{
          window.scrollBy({top:1200,behavior:'smooth'});
        }}>Why us</li>
        <li
        onClick={()=>{
          window.scrollBy({top:1890,behavior:'smooth'});
        }}>Plan</li>
        <li
        onClick={()=>{
          window.scrollBy({top:2540,behavior:'smooth'});
        }}>Testimonials</li>
      </ul>
    </div>
    
  )
}

export default Header
