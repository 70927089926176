import React from 'react'
import './Hero.css'
import Header from '../header/Header'
import heroImage from '../../assets/hero_image.png'
import herobackground from '../../assets/hero_image_back.png'
import {motion} from 'framer-motion'
const Hero = () => {
  const transition={type: 'spring' ,duration: 3}
  return (
    <div className="hero">
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header/>
        <div className="best-ad">
          <motion.div
            initial={{left:'238px'}}
            whileInView={{left:'8px'}}
            transition={{...transition, type:'tween'}}
          ></motion.div>
          <span>THE BEST FITNESS CLUB IN THE TOWN</span>
        </div>
        <div className='hero-title'>
          <span className='stroke-text'>SHAPE </span>
          <span>YOUR</span>
        </div>
        <div className="hero-title2">
          <span>IDEAL </span>
          <span>BODY</span>
        </div>
        <div className="description">
          <span>Here we will help you to get your Ideal body and shape</span>
        </div>
        <div className="count">
          <div>
            <span> 150+</span>
            <span>Expert Coaches</span>
          </div>
          <div>
            <span>1000+</span>
            <span>Member Join</span>
          </div>
          <div>
            <span>50+</span>
            <span>Training Course</span>
          </div>
        </div>
        <div className="hero-button">
          <button className="btn" 
          onClick={()=>{
            window.scrollBy({top:3000,behavior:'smooth'})
          }}
          >Get Start</button>
          <button className="btn">Learn More</button>
        </div>
      </div>
      <div className="right-h">  
        <button className='btn'>Register Now</button>
        <img src={heroImage} alt="" className='heroImage' />
        <motion.img
        initial={{opacity:0,x:100}}
        animate={{opacity:1,x:0}}
        transition={transition} 
        src={herobackground} alt="" className="herobackground"  />
        
      </div>

    </div>
  )
}

export default Hero
